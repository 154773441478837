@use '../../../app.scss';

.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    clip-path: circle(40%);
    background-color: app.$dark;

    &__image {
        clip-path: circle(40%);
        object-fit: cover;
    }
}
