@use '../../../app.scss';

.dogProfilePic {
    width: 156px;
    height: 156px;
    border-radius: 50%;

    /* Inside Auto Layout */
    @supports (aspect-ratio: 1/1) {
        height: auto;
        width: 100%;
        aspect-ratio: 1/1;
    }
}

//Self note: make media queries
.dogCard {
    display: flex;
    flex-direction: row;

    padding: 2rem;
    width: 100%;

    margin-bottom: 2rem;

    background: #f5f5f5;

    &:hover {
        cursor: pointer;
        background-color: #f0f0f0;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.07);
    }

    border: 1px solid #e1e1e1;
    box-sizing: border-box;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 0.5rem;

    color: app.$body-color;

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        &-top {
            display: flex;
            justify-content: space-between;
        }
    }

    .vaccine-status {
        &--approved {
            color: app.$success;
        }

        &--pending {
            color: app.$warning;
        }

        &--denied {
            color: app.$danger;
        }
    }

    .dogCardLabel {
        font-weight: bold;

        margin-right: 1rem;
    }
}

.ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 10px;

    position: static;
    width: auto;
    height: 56px;
}

.addDog {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;

    position: static;
    width: 125px;
    height: 46px;
    left: 1235px;
    top: 0px;

    background-color: #7db06f;
}

.dogCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 20px;

    position: static;
    width: 454px;
    height: 54px;
    left: 0px;
    top: 0px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
}

// .vaccinationStatus{
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     align-items: center;
//     padding: 0px;

//     position: static;
//     width: 223px;
//     height: 24px;

//     /* Inside Auto Layout */

//     flex: none;
//     order: 1;
//     flex-grow: 0;
//     margin: 0px 0px 141px;
// }

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 20px;

    position: static;
    height: 92px;
    width: auto;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
}
