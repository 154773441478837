@use '../../../app.scss';

.file-thumbnail {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 126px;
    height: 126px;
    padding: 0.25rem;
    margin: 0.5rem;
    background-color: app.$gray-100;
    border: 1px solid app.$gray-200;
    border-radius: 0.25rem;
    font-size: 0.75rem;

    &:hover {
        box-shadow: app.$box-shadow-sm;
    }

    &__content {
        width: 100%;
        height: 100%;

        &.--clickable {
            cursor: pointer;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__remove {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.65rem;
        height: 1.65rem;
        padding: 0.1rem;
        z-index: 999;
        color: app.$danger;
        background-color: app.$gray-100;
        border-radius: 50%;
        transition-duration: 100ms;
        transition-property: color, width, height;

        &:hover {
            cursor: pointer;
            color: lighten(app.$danger, 10);
            width: 1.85rem;
            height: 1.85rem;
        }
    }

    &__approval {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.65rem;
        height: 1.65rem;
        padding: 0.1rem;
        z-index: 999;
        color: app.$gray-900;
        background-color: app.$info;
        border-radius: 50%;

        &.--approved {
            background-color: app.$success;
        }

        &.--denied {
            background-color: app.$warning;
        }
    }

    &__name {
        position: absolute;
        bottom: 0;
        background-color: app.$gray-100;
        padding: 0.1rem 0.25rem;
    }

    &__file-icon {
        height: 100%;
        width: 100%;
    }
}

@include app.media-breakpoint-up(sm) {
    .file-thumbnail {
        width: 156px;
        height: 156px;
    }
}
