@use '../../../app.scss';

.k9-navbar.navbar {
    position: sticky !important;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 51;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    > .container-fluid {
        padding: 0;
    }
}

@include app.media-breakpoint-up(sm) {
    .k9-navbar.navbar {
        position: fixed !important;
        font-size: 0.95rem;
        .btn {
            font-size: 0.95rem;
        }
    }
}

@include app.media-breakpoint-up(xl) {
    .k9-navbar.navbar {
        font-size: 1rem;
        .btn {
            font-size: 1rem;
        }
        padding-left: 2.5rem;
        padding-right: 3.5rem;
    }
}
