@use '../../../app.scss';

.dogSetup {
    &__editor {
        &__profile-picture {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 15%;
        }
    }
}

.ClassTableContainer {
    margin-top: 20px;

}

.ClassTableContainer_Header {
    width: 85px;
    height: 29px;

    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

#Dog_Class_Search_Bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 255px;
    height: 29px;
    border-radius: 4px;
}

.Show_Past_Classes_Dogs {
    margin-right: 10px;
}

.ClassTableContainer_HeaderInput {
    width: 550px;
}
