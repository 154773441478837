@use '../../../app.scss';

.fileDropZone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    background-color: app.$gray-100;
    border: 2px dashed app.$gray-300;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition-duration: 200ms;
    transition-property: background-color, border-color;

    &:hover {
        cursor: pointer;
        background-color: app.$gray-200;
        border-color: app.$gray-400;
    }

    &-container--bordered {
        border: 1px solid app.$gray-300;
        border-radius: 4px;
        padding: 0.25rem;
    }

    &__icon,
    &-profile__icon {
        width: 2rem;
        height: 2rem;
    }

    &__cta-container,
    &-profile__cta-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__cta,
    &-profile__cta {
        margin-bottom: 0.25rem;
        font-weight: bold;
        color: rgba(app.$gray-900, 0.8);
    }
    &__accept-text,
    &-profile__accept-text {
        color: rgba(app.$gray-900, 0.75);
        font-size: 0.65rem;
        text-transform: uppercase;
    }

    &-profile {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        height: 300px;

        @supports (aspect-ratio: 1/1) {
            height: auto;
            width: 100%;
            aspect-ratio: 1/1;
        }

        align-items: center;
        font-size: 0.75rem;
        border: 2px dashed app.$gray-300;
        border-radius: 4px;
        padding: 1rem;
        margin-bottom: 1rem;
        color: app.$gray-100;

        &__overlay {
            position: absolute;
            background-color: rgba(app.$gray-900, 0.35);
            padding: 1rem;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition-duration: 200ms;
            transition-property: background-color, border-color;

            &:hover {
                background-color: rgba(app.$gray-900, 0.75);
            }
        }

        &--round {
            border-radius: 50%;
        }
        &--round > &__overlay {
            border-radius: 50%;
        }

        &__cta {
            color: app.$gray-100;
        }
        &__accept-text {
            color: app.$gray-100;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

@include app.media-breakpoint-up(sm) {
    .fileDropZone {
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        padding: 2rem;

        &-profile {
            min-width: 156px;
        }

        &__accept-text,
        &-profile__accept-text {
            font-size: 0.75rem;
        }

        &-container--bordered {
            padding: 1rem;
        }
    }
}
