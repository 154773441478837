@use '../../../app.scss';

$inner-border-width: 1px !default;
$outer-border-width: 4px !default;
$top-row-bg-color: rgba(app.$primary, 0.16) !default;

.k9-table {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__table {
        margin: 2rem 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;

        th,
        td {
            padding: 0.25rem 1.25rem;
            border: outset rgba(0, 0, 0, 0.125);
            border-width: $inner-border-width;
        }

        thead {
            tr {
                color: app.$gray-100;
                background-color: app.$secondary;
            }
            th {
                border-top-width: $outer-border-width;
            }
            th:first-of-type {
                border-radius: 0.5rem 0 0 0;
                border-left-width: $outer-border-width;
            }
            th:last-of-type {
                border-radius: 0 0.5rem 0 0;
                border-right-width: $outer-border-width;
            }
        }
        tbody {
            tr {
                color: app.$body-color;
                background-color: app.$gray-100;

                &:hover {
                    background-color: app.$gray-200;
                }

                &.k9-table__top-row {
                    background-color: $top-row-bg-color;

                    &:hover {
                        background-color: darken($top-row-bg-color, 15);
                    }
                }
            }
            tr {
                td:first-of-type {
                    border-left-width: $outer-border-width;
                }
                td:last-of-type {
                    border-right-width: $outer-border-width;
                }
            }
            tr:last-of-type {
                td {
                    border-bottom-width: $outer-border-width;
                }
                td:first-of-type {
                    border-radius: 0 0 0 0.5rem;
                }
                td:last-of-type {
                    border-radius: 0 0 0.5rem 0;
                }
            }
        }
    }

    &__extras-container {
        display: flex;
        justify-content: space-between;
    }

    &__pagination-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    &__footnotes-container {
        flex: 1;
        p {
            margin: 0;
            margin-bottom: 0.5rem;
            color: app.$text-muted;
        }
    }
}

@include app.media-breakpoint-down(lg) {
    .k9-table {
        &__table {
            thead {
                display: none;
            }

            tbody tr:first-of-type {
                margin-top: 0;
            }

            tr {
                display: block;
                margin-top: 0.2rem;
                border-style: outset;
                border-color: rgba(0, 0, 0, 0.125);
                border-width: $outer-border-width;
                border-radius: 0.5rem;

                &.k9-table__top-row {
                    margin-top: 0.8rem;
                }

                td {
                    display: block;
                    position: relative;
                    text-align: left;
                    border: none;
                    border-bottom: outset rgba(0, 0, 0, 0.125);
                    border-bottom-width: calc($inner-border-width / 2);
                    min-height: 2rem;
                    padding: 0;

                    .breakword {
                        overflow-wrap: anywhere;
                        word-break: break-all;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    > .expander {
                        text-align: end;
                    }

                    > *:not(.expander) {
                        text-align: start !important;
                        justify-content: flex-start !important;
                    }

                    .k9-table__cell {
                        padding: 0.25rem 1.25rem;
                    }

                    .k9-table__floating-label {
                        display: inline-block;
                        color: app.$text-muted;
                        padding: 0.125rem 0.5rem;
                        font-weight: 600;
                    }

                    &:first-child {
                        .k9-table__floating-label {
                            border-radius: 0.25rem 0 0.25rem 0;
                        }
                    }
                }
            }

            tbody tr:last-of-type {
                td {
                    border-bottom-width: calc($inner-border-width / 2);
                }
                td:first-of-type {
                    border-radius: 0;
                }
            }
        }
    }
}
