.modal {
    z-index: 3;
    display: none;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}
.modal-container {
    margin: auto;
    background-color: #fff;
    position: relative;
    padding: 0;
    outline: 0;
    width: 600px;
    padding: 20px;
}

@media (max-width: 768px) {
    .modal {
        padding-top: 50px;
    }

    .modal-container {
        width: 500px;
    }
}

@media (max-width: 500px) {
    .modal-container {
        width: 300px;
    }
}
