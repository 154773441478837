@use '../../../../app.scss';

.classCard {
    cursor: pointer;
    box-shadow: app.$box-shadow-sm;
    transition: box-shadow 0.2s, transform 0.2s;

    .card-img-top {
        height: max(30vh, 15rem);
        object-fit: cover;
        object-position: center;
    }

    &:hover {
        box-shadow: app.$box-shadow;
        transform: translate(0, -0.2rem);
    }
}

.classThumbnails {
    height: 250px;
    width: 100%;
    object-fit: cover;
    box-shadow: app.$box-shadow;
}

.heroImg {
    height: 50vh;
    width: 100%;
    object-fit: cover;
}
