@import './_colors.scss';

// Variables
$font-weight-bold: 600;

// Options

$enable-shadows: true;

// Spacers
$spacer: 1rem; // = 16px or the HTML tag's font-size
$spacers: () !default; // need this declared so I can override it under
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    // = 4px
    2:
      (
        $spacer * 0.5,
      ),
    // = 8px
    3: $spacer,
    // = 16px
    4:
      (
        $spacer * 1.25,
      ),
    // = 20px
    5:
      (
        $spacer * 2.5,
      )
      // = 40px,
  ),
  $spacers
);

// Navbar
$navbar-padding-x: 2.5rem;
$nav-link-font-weight: 600;

// Buttons
$btn-font-weight: $font-weight-bold;

// Keep this at the end of the file!
@import 'bootstrap/scss/bootstrap.scss';
@import 'react-bootstrap-typeahead/css/Typeahead.css';
@import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
