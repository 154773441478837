.classTypeSetup {
    &__editor {
        &__top {
            display: flex;
            align-items: center;

            form {
                flex: 1;
                margin-left: 2rem;
            }
        }
        &__photos {
            &-header {
                display: flex;
                margin-bottom: 1rem;

                h3 {
                    margin-right: 1rem;
                }
            }
        }
    }
}
