.profile-picture {
    position: relative;
    width: 250px;
    height: 250px;
    border: 2px solid #7db06f;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    height: auto;
}

.overlay:hover {
    opacity: 1;
}

.container {
    position: relative;
    width: 250px;
    height: 250px;
}

.overlay {
    width: 250px;
    height: 250px;
    position: absolute;
    border-radius: 50%;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 0.5s ease;
    border: 2px solid #7db06f;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}
