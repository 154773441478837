@use '../../../app.scss';

.profileBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;

    .avatar {
        margin-right: 0.25rem;
    }

    &--clickable {
        cursor: pointer;
        color: app.$primary;
        text-decoration: underline;

        &:hover {
            background-color: rgba(black, 0.05);
        }
        &:active {
            background-color: rgba(black, 0.1);
        }
    }
}
